
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Calendar } from 'vant'

@Component({
  components: {
    [Calendar.name]: Calendar
  }
})
export default class SelectDate extends Vue {
  @Prop({ type: Boolean, default: false }) showDate!: boolean
  @Prop({ type: Number, default: 7 }) maxRange
  @Prop({ type: Array, default: [Vue.prototype.$moment().format('yyyy-MM-DD'), Vue.prototype.$moment().add(1, 'days').format('yyyy-MM-DD')] }) dateArr
  public defaultDate = []
  public isShowDate: boolean = false
  @Watch('showDate') watchShow(newVal: boolean) {
    this.isShowDate = newVal
  }
  @Watch('dateArr',{deep:true,immediate:true}) watchDate(newVal) {
    this.defaultDate = [new Date(newVal[0]), new Date(newVal[1])]
  }

  public minDate = new Date()
  public formatter(day: any) {
    if (day.type === 'start') {
      day.bottomInfo = '入住'
    } else if (day.type === 'end') {
      day.bottomInfo = '离店'
    }
    return day
  }
  public handleConfirm(date: [Date, Date]) {
    let star = this.$moment(date[0]).format('yyyy-MM-DD')
    let end = this.$moment(date[1]).format('yyyy-MM-DD')
    let sum = this.$moment(date[1]).diff(this.$moment(date[0]), 'days')
    this.close()
    this.$emit('confirm', { star, end, sum })
  }
  public close() {
    this.$emit('update:showDate', false)
  }
  

}


import { Component, Vue } from "vue-property-decorator";
import { fectHotelDetailApi } from "@/api/hotel/index.api";
import {
  Swipe,
  SwipeItem,
  Lazyload,
  ImagePreview,
  Loading,
  Rate,
  Icon,
  Divider,
  Collapse,
  CollapseItem,
  Cell,
  Popup,
  Button,
  Empty,
} from "vant";
import SelectDate from "@/components/selectDate/index.vue";
import { weekey } from "@/utils/date";
import FotelFacility from "@/components/hotelFacility/index.vue";

Vue.use(Lazyload, {
  lazyComponent: true,
});
@Component({
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Loading.name]: Loading,
    [Rate.name]: Rate,
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Empty.name]: Empty,
    SelectDate,
    FotelFacility,
  },
})
export default class HotelDetail extends Vue {
  public queryHotel = {
    Action: "HotelDetailPrice",
    CheckInDate: this.$moment().format("yyyy-MM-DD"),
    CheckOutDate: this.$moment().add(1, "days").format("yyyy-MM-DD"),
    MoreDetail: true,
    HotelCode: "",
  };
  public hotelData: any = {};

  public hotelDetail: any = {};
  public imageList = [];
  public currentImgIndex = 0;
  public async getHotelDetailData() {
    try {
      this.$toast.loading({
        message: "搜索中...",
        loadingType: "spinner",
        duration: 0,
      });
      let { data } = await fectHotelDetailApi(this.queryHotel);
      if (data.Status == 200) {
        this.$set(this, "hotelDetail", JSON.parse(data.ReturnJson));
        console.log(this.hotelDetail);
        this.hotelDetail.HotelLevel = +this.hotelDetail.HotelLevel;
        this.imageList = this.hotelDetail.HotelPictures.map(
          (item) => item.PictureUrl
        );
      }
    } finally {
      this.$toast.clear();
    }
  }
  public isShowBigImg: boolean = false;
  public handleShowBigImg(index) {
    this.isShowBigImg = true;
    this.currentImgIndex = index;
  }

  public showDate: boolean = false;
  public sumDate = 1;
  public handleConfirm(date) {
    this.queryHotel.CheckInDate = date.star;
    this.queryHotel.CheckOutDate = date.end;
    this.sumDate = date.sum;
    sessionStorage.setItem(
      "queryHotelData",
      JSON.stringify({
        ...this.hotelData,
        BeginDate: date.star,
        EndDate: date.end,
      })
    );
    this.getHotelDetailData();
  }
  public getWeekey(date) {
    if (date == this.$moment().format("yyyy-MM-DD")) return "今日";
    else return weekey(this.$moment(date).format("d"));
  }
  public getDays(date) {
    return this.$moment(date).format("MM月DD日");
  }

  public noImg = require("@/assets/image/img_hotel_thumbnail_default.png");
  public activeIndex = null;

  public isShowRoomDetail: boolean = false;
  public currentRoomImg = 0;
  public onChangeImg(index) {
    this.currentRoomImg = index;
  }
  public roomDetailObj = {};
  public getRoomDetail(item, index) {
    this.currentRoomImg = 0;
    this.isShowRoomDetail = true;
    this.roomDetailObj = item;
    this.activeCurrentIndex = +index;
  }
  public activeCurrentIndex = null;
  public clooseRoomDetailModel() {
    this.isShowRoomDetail = false;
    this.activeIndex = this.activeCurrentIndex;
  }

  toFillPage(room) {
    this.$router.push({
      path: "/hotelFill",
      query: {
        hotelInfo: JSON.stringify({
          ...room,
          CheckInDate: this.queryHotel.CheckInDate,
          CheckOutDate: this.queryHotel.CheckOutDate,
          sumDate: this.sumDate,
          HotelName: this.hotelDetail.HotelName,
          HotelAddress: this.hotelDetail.HotelAddress,
          HotelCode: this.queryHotel.HotelCode,
        }),
      },
    });
  }

  public isShowHotelFacility: boolean = false;

  public toHotelTim(val) {
    let hotelDetail = {
      GDLatitude: val.GDLatitude,
      GDLongitude: val.GDLongitude,
      HotelName: val.HotelName,
    };
    this.$router.push({
      path: "/hotelDetail/hotelRim",
      query: hotelDetail,
    });
  }

  created() {
    this.hotelData =
      sessionStorage.getItem("queryHotelData") &&
      JSON.parse(sessionStorage.getItem("queryHotelData") as any);
    this.queryHotel.CheckInDate =
      this.hotelData.BeginDate || this.queryHotel.CheckInDate;
    this.queryHotel.CheckOutDate =
      this.hotelData.EndDate || this.queryHotel.CheckOutDate;
    this.sumDate = this.$moment(this.queryHotel.CheckOutDate).diff(
      this.$moment(this.queryHotel.CheckInDate),
      "days"
    );
    if (this.$route.query.HotelCode) {
      this.queryHotel.HotelCode = this.$route.query.HotelCode + "";
      this.getHotelDetailData();
    }
  }
}

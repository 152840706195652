
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Popup,
  Icon,
  NavBar,
  Swipe,
  SwipeItem,
  ImagePreview,
  Empty,
  Rate,
  Tab,
  Tabs,
} from "vant";
import clipboard from "clipboard";
@Component({
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Empty.name]: Empty,
    [Rate.name]: Rate,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
})
export default class HotelFacility extends Vue {
  @Prop({ type: Object, default: () => ({}) }) hotelDetail;
  @Prop({ type: Boolean, default: false }) isShowHotelFacility;

  public isShow: boolean = false;
  @Watch("isShowHotelFacility", { immediate: true, deep: true }) watchData(
    newVal
  ) {
    if (newVal) {
      setTimeout(() => {
        this.getMapData();
      }, 1000);
    }
    this.isShow = newVal;
  }

  public isShowBigImg: boolean = false;
  public currentImgIndex = 0;
  public handleShowBigImg(index) {
    this.isShowBigImg = true;
    this.currentImgIndex = index;
  }

  public isShowShortBrief: boolean = true;

  get imageList() {
    console.log("this.hotelDetail", this.hotelDetail);
    return (
      this.hotelDetail &&
      this.hotelDetail.HotelPictures &&
      this.hotelDetail.HotelPictures.map((item) => item.PictureUrl)
    );
  }

  copyOrderNum() {
    new clipboard("#copy");
    this.$toast.success("复制成功");
  }

  public activeTabIndex = 1;

  getMapData() {
    let that = this;
    let map = new AMap.Map("container", {
      center: [that.hotelDetail.GDLongitude, that.hotelDetail.GDLatitude],
      viewMode: "2D", // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
      zoom: 15, // 初始化地图层级
    });
    let marker = new AMap.Marker({
      position: new AMap.LngLat(
        that.hotelDetail.GDLongitude,
        that.hotelDetail.GDLatitude
      ),
      icon: require("@/assets/image/facilities_map_icon.png"), // 添加 Icon 图标 URL
      // topWhenClick: true // 鼠标点击时marker是否置顶，默认false
      title: that.hotelDetail.HotelName,
    });
    // 设置文本标签;
    marker.setLabel({
      direction: "top",
      offset: new AMap.Pixel(0, -2), // 设置文本标注偏移量
      content: `
          <div class='map_info>
            <div class='info_left'>${that.hotelDetail.HotelName}</div>
          </div>`,
    });

    marker.setMap(map);
  }
}
